import Dialog from '@reach/dialog';
import './ImageOverlay.scss';
import React, { useState } from 'react';
import VisuallyHidden from '@reach/visually-hidden';

export default function ImageOverlay({ showDialog, children, close }) {
  return (
    <>
      <Dialog
        isOpen={showDialog}
        onDismiss={close}
        aria-labelledby="dialog-heading"
        aria-describedby="dialog-description"
      >
        <button className="close-button" onClick={close}>
          <VisuallyHidden>Close</VisuallyHidden>
          <span aria-hidden className="close-button__x">
            &times;
          </span>
        </button>
        <VisuallyHidden>
          <header id="dialog-heading">Full size</header>
        </VisuallyHidden>
        <VisuallyHidden id="dialog-description">
          The full sized image.
        </VisuallyHidden>
        {children}
      </Dialog>
    </>
  );
}
