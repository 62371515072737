import React, { useState, useMemo } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import VisuallyHidden from '@reach/visually-hidden';
import Nav from '../components/Nav/Nav';
import SEO from '../components/SEO';
import ImageOverlay from '../components/ImageOverlay/ImageOverlay';

import '../assets/stylesheets/pages/index.scss';

function randomWithinLimit(limit) {
  return Math.floor(Math.random() * limit) || 1;
}

function randomWithinRange(lowerLimit = 0, upperLimit = 1) {
  const ret =
    Math.floor(Math.random() * (upperLimit - lowerLimit) + lowerLimit) ||
    lowerLimit;
  return ret;
}

export default function HomePage({ data }) {
  const [dialogImage, setDialogImage] = useState();
  const [dialogWords, setDialogWords] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const showDialogImage = (Img) => {
    setDialogImage(Img);
    open();
  };

  const showDialogWords = (words) => {
    setDialogImage(null);
    setDialogWords(words);
    open();
  };

  // O(n) time & O(n) space
  // Assumes pre-sorted arrays
  function mergeImagesAndWords(imageArray, wordsArray) {
    const merged = [];
    let index1 = 0;
    let index2 = 0;
    let current = 0;

    while (current < imageArray.length + wordsArray.length) {
      const imageArrayDepleted = index1 >= imageArray.length;
      const wordsArrayDepleted = index2 >= wordsArray.length;

      if (
        !imageArrayDepleted &&
        (wordsArrayDepleted ||
          Date.parse(imageArray[index1].node.birthTime) >
            Date.parse(wordsArray[index2].node.frontmatter.publishDate))
      ) {
        merged[current] = imageArray[index1];
        merged[current].type = 'image';
        index1++;
      } else {
        merged[current] = wordsArray[index2];
        merged[current].type = 'words';
        index2++;
      }
      current++;
    }

    return merged;
  }

  const merged = mergeImagesAndWords(data.allImages.edges, data.allPosts.edges);
  // We want to memoize the result of assigning random sizes for each image within a css grid.
  // It really doesn't matter if the state persists between page loads,
  // But while you're clicking around on the page, the sizes of images in the grid should staty the same.
  // Only runs if data.allImages.edges.length changes
  // Each entry is an array in the form [vertical grid cell count, horizontal grid cell count]
  const bakedImageSizes = useMemo(() => {
    const imageSizeArray = [];
    for (let i = 0; i <= merged.length; i++) {
      if (i === 0 || i === 1) {
        imageSizeArray.push([4, 5]);
      } else if (i === 2 || i === 3 || i === 4 || i === 5) {
        imageSizeArray.push([3, 5]);
      } else if (i === 6 || i === 7 || i === 8 || i === 9) {
        imageSizeArray.push([2, 5]);
      } else {
        imageSizeArray.push([randomWithinRange(2, 5), randomWithinRange(2, 5)]);
      }
    }
    return imageSizeArray;
  }, [merged.length]);

  return (
    <>
      <SEO title="Welcome to Cosmozo - We are here to entertain." />
      <ImageOverlay showDialog={showDialog} close={close}>
        {dialogImage || dialogWords}
      </ImageOverlay>
      <h1>Cosmozo</h1>
      <div className="illustration-grid">
        {merged.map((element, index) => {
          if (element.type === 'image') {
            const queryImage = element.node.childImageSharp;
            let fullSizeImageMetadata = '';
            let figCaption = '';
            if (queryImage.fields !== null) {
              fullSizeImageMetadata = (
                <article className="modal-content--image__metadata">
                  <h1>{queryImage.fields.exif.raw.image.DocumentName}</h1>
                  <p>{queryImage.fields.exif.raw.image.ImageDescription}</p>
                  <footer>{queryImage.fields.exif.raw.image.Copyright}</footer>
                </article>
              );
              figCaption = (
                <figCaption>
                  <VisuallyHidden>
                    {queryImage.fields.exif.raw.image.ImageDescription}
                  </VisuallyHidden>
                </figCaption>
              );
            }
            const fullSizeImage = (
              <div className="modal-content--image">
                <Img key={index} fluid={queryImage.fluid} />
                {fullSizeImageMetadata}
              </div>
            );
            const gridImage = (
              <figure>
                <Img key={index} fixed={queryImage.fixed} />
                {figCaption}
              </figure>
            );
            return (
              <a
                key={index}
                tabIndex={index}
                role="button"
                onClick={() => {
                  showDialogImage(fullSizeImage);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 32) {
                    showDialogImage(fullSizeImage);
                  }
                }}
                title={
                  fullSizeImageMetadata
                    ? queryImage.fields.exif.raw.image.DocumentName
                    : 'Another awesome creative illustration.'
                }
                className={`illustration-grid__item v${bakedImageSizes[index][0]} h${bakedImageSizes[index][1]}`}
              >
                {gridImage}
              </a>
            );
          }
          if (element.type == 'words') {
            const gridWords = (
              <>
                <p>Read all about it</p>
                <h1>{element.node.frontmatter.headline}</h1>
              </>
            );
            const allWords = (
              <div className="modal-content--blog">
                <h1>{element.node.frontmatter.headline}</h1>
                <h2>{element.node.frontmatter.subhead}</h2>
                <time dateTime={element.node.frontmatter.publishDate}>
                  {new Date(
                    element.node.frontmatter.publishDate
                  ).toLocaleDateString()}
                </time>
                <div dangerouslySetInnerHTML={{ __html: element.node.html }} />
              </div>
            );
            return (
              <a
                className={`illustration-grid__item v${bakedImageSizes[index][0]} h${bakedImageSizes[index][1]}`}
                tabIndex={index}
                key={`word-${index}`}
                role="button"
                onClick={() => {
                  showDialogWords(allWords);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 32) {
                    showDialogWords(allWords);
                  }
                }}
              >
                {gridWords}
              </a>
            );
          }
        })}
      </div>
      <Nav />
    </>
  );
}

// Two options
// one we make one query that pre sorts both types for us -
// or what I'm leaning towards is interweave the two.
// How do you do that
// We know both lists are in order.  Next item is guaranteed to be higher than current.
// Grab first item from first list.  Grab first item from second list.
// Compare.  If first is first put it in new list.
// If second list item is first, put that in the new list.
// Advance the list you were in.  Compare. Put the appropriate item in the new list.
// If inner loop is advanced, the inner array must shrink so that when its looped again it will be where it left off

export const query = graphql`
  query($skip: Int = 0, $pageSize: Int = 100) {
    allImages: allFile(
      filter: { sourceInstanceName: { eq: "images" } }
      sort: { order: DESC, fields: birthTime }
    ) {
      edges {
        node {
          name
          birthTime
          childImageSharp {
            fixed(height: 558) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
            fields {
              exif {
                raw {
                  image {
                    ImageDescription
                    DocumentName
                    Copyright
                  }
                }
              }
            }
          }
        }
      }
    }
    allPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___publishDate] }
      limit: $pageSize
      skip: $skip
    ) {
      edges {
        node {
          html
          frontmatter {
            slug
            headline
            subhead
            publishDate
            updateDate
          }
        }
      }
    }
  }
`;
